<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-craigtoun-customers' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Customers</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>{{ customer.full_name }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.customerDialog.open(customer)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Season Tickets</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table v-if="customer.season_tickets.length > 0">
              <thead>
                <tr>
                  <th>Identifier</th>
                  <th>Pass Type</th>
                  <th>Status</th>
                  <th>Expiry Date</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="season_ticket in customer.season_tickets"
                  v-bind:key="season_ticket.id"
                >
                  <td>
                    <router-link
                      :to="{
                        name: 'module-craigtoun-tickets-individual',
                        params: { ticketId: season_ticket.id },
                      }"
                      >{{ season_ticket.identifier }}</router-link
                    >
                  </td>
                  <td>
                    <v-chip small label>{{
                      season_ticket.ticket_type.title
                    }}</v-chip>
                  </td>
                  <td>
                    <v-chip
                      v-if="
                        new Date().toISOString().substring(0, 10) >
                        season_ticket.valid_to
                      "
                      small
                      label
                      color="red"
                      text-color="white"
                      >Expired</v-chip
                    >
                    <v-chip
                      v-else
                      small
                      label
                      :color="getSeasonPassStatusColor(season_ticket.status)"
                      >{{
                        getSeasonPassStatusLabel(season_ticket.status)
                      }}</v-chip
                    >
                  </td>
                  <td>{{ season_ticket.formatted_dates.valid_to }}</td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="green lighten-4 green--text"
                          v-on="on"
                          :to="{
                            name: 'module-craigtoun-tickets-individual',
                            params: { ticketId: season_ticket.id },
                          }"
                        >
                          <v-icon x-small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-card-text v-else> No Season Tickets </v-card-text>
          </v-card>
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Booked Events</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table v-if="customer.event_tickets.length > 0">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Date</th>
                  <th>Total Tickets</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="event_ticket in customer.event_tickets"
                  v-bind:key="event_ticket.id"
                >
                  <td>{{ event_ticket.event.name }}</td>
                  <td v-if="event_ticket.time_block">
                    {{ event_ticket.time_block.formatted_dates.date_alt }}
                  </td>
                  <td v-else>
                    {{ event_ticket.event.formatted_dates.start_date }}
                  </td>
                  <td>
                    <div v-if="event_ticket.event.event.type === 'christmas'">
                      {{ parseInt(event_ticket.total_tickets) }}
                    </div>
                    <div v-else-if="event_ticket.event.is_paid">
                      {{
                        parseInt(event_ticket.total_tickets_over_3) +
                        parseInt(event_ticket.total_tickets_under_3)
                      }}
                    </div>
                    <div v-else>N/A</div>
                  </td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="green lighten-4 green--text"
                          v-on="on"
                          :to="{
                            name: 'module-craigtoun-events-individual',
                            params: { eventId: event_ticket.event.id },
                          }"
                        >
                          <v-icon x-small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Event</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-card-text v-else> No Events Booked </v-card-text>
          </v-card>
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Venue Hires</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table v-if="customer.venue_hires.length > 0">
              <thead>
                <tr>
                  <th>Venue</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Total Attendees</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="venue_hire in customer.venue_hires"
                  v-bind:key="venue_hire.id"
                >
                  <td>{{ venue_hire.venue.name }}</td>
                  <td>{{ venue_hire.formatted_dates.date }}</td>
                  <td>{{ venue_hire.start_time }}</td>
                  <td>{{ venue_hire.end_time }}</td>
                  <td>{{ venue_hire.total_attendees }}</td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.eventDialog.open(venue_hire)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-card-text v-else> No Venue Hires </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title> Basic Details</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.customerDialog.open(customer)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>

            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>{{ customer.full_name }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ customer.email }}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{{ customer.phone }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td style="padding-top: 13px; padding-bottom: 13px">
                    <div>{{ customer.address_line_1 }}</div>
                    <div>{{ customer.address_line_2 }}</div>
                    <div>{{ customer.address_company }}</div>
                    <div>{{ customer.address_city }}</div>
                    <div>{{ customer.address_postcode }}</div>
                    <div>{{ customer.address_county }}</div>
                    <div>{{ customer.address_country }}</div>
                  </td>
                </tr>
                <tr>
                  <th>Gift Aid</th>
                  <td style="padding-top: 13px; padding-bottom: 13px">
                    <div>{{ customer.gift_aid ? "Yes" : "No" }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Supporter Membership</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-divider></v-divider>
            <v-simple-table v-if="customer.memberships.length > 0">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Expiry Date</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="membership in customer.memberships"
                  v-bind:key="membership.id"
                >
                  <td>
                    <v-chip
                      v-if="
                        new Date().toISOString().substring(0, 10) >
                        membership.valid_to
                      "
                      small
                      label
                      color="red"
                      text-color="white"
                      >Expired</v-chip
                    >
                    <v-chip
                      v-else
                      small
                      label
                      :color="getSeasonPassStatusColor(membership.status)"
                      >{{ getSeasonPassStatusLabel(membership.status) }}</v-chip
                    >
                  </td>
                  <td>{{ membership.formatted_dates.valid_to }}</td>
                  <td class="text-right">
                    <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="green lighten-4 green--text"
                          v-on="on"
                          :to="{
                            name: 'module-craigtoun-tickets-individual',
                            params: { ticketId: membership.id },
                          }"
                        >
                          <v-icon x-small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip> -->
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-card-text v-else> No Supporter Memberships </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <customer-dialog ref="customerDialog" />
    <event-dialog ref="eventDialog" />
  </div>
</template>

<script>
import CustomerDialog from "./components/CustomerDialog.vue";
import EventDialog from "../venues/components/EventDialog";
import { craigtoun } from "../../mixins";

export default {
  mixins: [craigtoun],

  props: {
    appColor: {
      type: String,
    },
  },

  components: {
    CustomerDialog,
    EventDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Customers",
          disabled: false,
          to: { name: "module-craigtoun-customers" },
          exact: true,
        },
        {
          text: "Customer",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.getters["craigtoun/customersStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.customer.full_name,
      disabled: true,
    });
  },
};
</script>
